body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto-Condensed','HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
}


.border-black { border-color: black !important; }
.fjalla { font-family: "Fjalla One", "Roboto Condensed", Helvetica, Arial, 'Lucida Grande', sans-serif; }

.pointer { cursor: pointer; }
